@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /* Colors */
  --primary-color: #102037;
  --secondary-color: #ffffff;
  --dark-grey-color: #7e7e7e;
  --light-grey-color: #f3f3f3;
  --silver-color: #ababab;
  --dark-blue-color: #4e89ae;
  --light-blue-color: #5da1cc;
  --dark-red-color: #ff4844;
  --light-red-color: #ed6663;
  --dark-orange-color: #ff894b;
  --light-orange-color: #ffa372;

  /* Font Sizes */
  --display-1: 6rem;
  --display-2: 5.5rem;
  --display-3: 4.5rem;
  --display-4: 3.5rem;

  /* Font Weight */
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
}

* {
  font-family: "Public Sans", sans-serif;
}

/* Font Sizes */
.container-xl{
  max-width: 1400px !important;
}
.lg-text-1 {
  font-size: var(--display-1);
}

.lg-text-2 {
  font-size: var(--display-2);
}

.lg-text-3 {
  font-size: var(--display-3);
}

.lg-text-4 {
  font-size: var(--display-4);
}

/* Font Weight */

.font-weight-100 {
  font-weight: var(--font-weight-100);
}

.font-weight-200 {
  font-weight: var(--font-weight-200);
}

.font-weight-300 {
  font-weight: var(--font-weight-300);
}

.font-weight-400 {
  font-weight: var(--font-weight-400);
}

.font-weight-500 {
  font-weight: var(--font-weight-500);
}

.font-weight-600 {
  font-weight: var(--font-weight-600);
}

.font-weight-700 {
  font-weight: var(--font-weight-700);
}

.font-weight-800 {
  font-weight: var(--font-weight-800);
}

.font-weight-900 {
  font-weight: var(--font-weight-900);
}

/* Primary Title */

.border-center:before,
.border-center:after {
  background-color: var(--primary-color);
  content: "";
  display: inline-block;
  height: 0.1rem;
  position: relative;
  vertical-align: middle;
  width: 30%;
}

.border-center:before {
  right: 0.5em;
  margin-left: -50%;
}

.border-center:after {
  left: 0.5em;
  margin-right: -50%;
}

/* Background Color */

.bg-blue {
  background-color: var(--primary-color) !important;
}

.font-color{
  color: var(--primary-color) !important;
}

/* Border Radius */

.border-10 {
  border-radius: 10px !important;
}

.border-15 {
  border-radius: 15px !important;
}

.border-12 {
  border-radius: 12px !important;
}

.border-20 {
  border-radius: 20px !important;
}

.border-30 {
  border-radius: 30px !important;
}

/* Box Shadow */

.card-shadow {
  box-shadow: 0px 4px 16px rgb(78 137 174 / 65%);
}

/* Borders */

.judgements-border-1 {
  background-color: var(--dark-blue-color);
  height: 8px;
  border-radius: 20px 20px 0 0;
}

.judgements-border-2 {
  background-color: var(--light-red-color);
  height: 8px;
  border-radius: 20px 20px 0 0;
}

.judgements-border-3 {
  background-color: var(--dark-orange-color);
  height: 8px;
  border-radius: 20px 20px 0 0;
}

/* Border Left */

.border-left {
  background-color: var(--primary-color);
  height: 0.1rem;
  width: 20%;
  float: left;
}

/* Buttons */

button.btn-white {
  color: var(--primary-color);
  background-color: var(--secondary-color);
}

.acts-btn-group {
  display: flex;
}

.acts-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  padding: 0.35rem 2.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 8px;
  border: 2px solid var(--primary-color);
  margin: 10px;
  color: var(--primary-color) !important;
}

.acts-btn:hover,
.acts-active {
  background-color: var(--primary-color);
  color: var(--secondary-color) !important;
  opacity: 1;
  text-decoration: none !important;
  transition: 0.6s;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
}

.secondary-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  background-color: var(--primary-color);
  padding: 0.2rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 20px;
  border: 2px solid var(--primary-color);
  color: var(--secondary-color);
}

/* Color Group */

.color-group {
  display: flex;
}

.color-item-1 {
  background-color: var(--dark-blue-color);
  height: 5px;
  width: 15%;
}

.color-item-2 {
  background-color: var(--light-blue-color);
  height: 5px;
  width: 15%;
}

.color-item-3 {
  background-color: var(--light-red-color);
  height: 5px;
  width: 15%;
}

.color-item-4 {
  background-color: var(--dark-red-color);
  height: 5px;
  width: 15%;
}

.color-item-5 {
  background-color: var(--light-orange-color);
  height: 5px;
  width: 15%;
}

.color-item-6 {
  background-color: var(--dark-orange-color);
  height: 5px;
  width: 15%;
}

.color-item-lg-1 {
  background-color: var(--dark-blue-color);
  height: 3px;
  width: 40%;
}

.color-item-lg-2 {
  background-color: var(--light-blue-color);
  height: 3px;
  width: 40%;
}

.color-item-lg-3 {
  background-color: var(--light-red-color);
  height: 3px;
  width: 40%;
}

.color-item-lg-4 {
  background-color: var(--dark-red-color);
  height: 3px;
  width: 40%;
}

.color-item-lg-5 {
  background-color: var(--light-orange-color);
  height: 3px;
  width: 40%;
}

.color-item-lg-6 {
  background-color: var(--dark-orange-color);
  height: 3px;
  width: 40%;
}

.vertical-line-1 {
  background-color: var(--dark-blue-color);
  height: 25px;
  width: 4px;
}

.vertical-line-2 {
  background-color: var(--light-blue-color);
  height: 25px;
  width: 4px;
}

.vertical-line-3 {
  background-color: var(--dark-red-color);
  height: 25px;
  width: 4px;
}

.vertical-line-4 {
  background-color: var(--light-red-color);
  height: 25px;
  width: 4px;
}

.vertical-line-5 {
  background-color: var(--light-orange-color);
  height: 25px;
  width: 4px;
}

.vertical-line-6 {
  background-color: var(--dark-orange-color);
  height: 25px;
  width: 4px;
}

/* Opacity */

.opacity-5 {
  opacity: 0.5;
}

/* Margins */

.p-top-1 {
  padding-top: 0.1rem !important;
}

.p-bottom-3 {
  padding-bottom: 3rem !important;
}

.mrt-mrb-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mrt-3 {
  margin-top: 3.3rem;
}

.mrt-5 {
  margin-top: 5rem;
}

/* Paddings */

.padding-2 {
  padding: 2.4rem !important;
}

/* Icons */

.bookmark-icon {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}

.bookmark-far-icon {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}

.bookmark-far-icon img {
  height: 10px;
  width: 10px;
  fill: #fff;
}

.bookmark-sm-icon {
  height: 24px;
  width: 24px;
  line-height: 24px;
  border-radius: 50%;
  font-size: 9px;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  position: absolute;
  text-align: center;
  cursor: pointer;
  right: 15px;
  bottom: 10px;
}

.download-sm-icon {
  line-height: 24px;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  cursor: pointer;
  right: 15px;
  margin-top: 10px;
}

.share-sm-icon {
  height: 24px;
  width: 24px;
  line-height: 24px;
  border-radius: 50%;
  font-size: 9px;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  position: absolute;
  text-align: center;
  cursor: pointer;
  right: 19px;
  bottom: 10px;
}

/* Horizontal Row */

.border {
  height: 0.07rem;
  background-color: var(--primary-color);
}

/* Flex Family */

.card-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Searchbar */

input.search-bar {
  background-color: var(--secondary-color);
  border-radius: 30px;
  width: 300px;
  border: 1.3px solid var(--silver-color);
  display: inline-block;
}

input.search-bar::placeholder {
  color: var(--silver-color);
}

input.search-bar:hover,
input.search-bar:focus {
  background-color: var(--secondary-color);
}

/* Navbar */

.nav-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.navbar-nav {
  align-items: center;
}

/* Welcome Page */

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff !important;
  background-color: #576273 !important;
  border: none !important;
  background-clip: padding-box;
}

.welcome-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.welcome-items {
  padding: 4rem;
  display: flex;
  align-items: center;
}

.welcome-btn {
  display: inline-block;
  width: 100%;
  font-weight: 600;
  color: var(--primary-color);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--secondary-color);
  border: 1px solid transparent;
  padding: 0.475rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 8px;
}

.welcome-form,
input.welcome-form {
  background: #576273;
  color: #ffffff;
  border-radius: 8px;
  border: none;
}

/* input.welcome-form:hover,
.welcome-form:hover,
input.welcome-form:active,
.welcome-form:active,
input.welcome-form:focus,
.welcome-form:focus {
  background-color: #576273 !important;
  color: #ffffff !important;
  border: none;
} */

.welcome-form:hover {
  background-color: #576273 !important;
  color: #ffffff !important;
  border: none;
}

.welcome-form:focus {
  background-color: #576273 !important;
  color: #ffffff !important;
  border: none;
}

.welcome-form:active {
  background-color: #576273 !important;
  color: #ffffff !important;
  border: none;
}

input::placeholder {
  color: var(--secondary-color) !important;
}

/* OTP Input */

.otp-input {
  margin-right: 10px;
}

.otp-input input {
  width: 4rem !important;
  height: 3rem;
  font-size: 2rem;
  border: 2px solid #ffffff !important;
  background-color: #576273 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
}

/* Hero Home */

.hero-home {
  height: 65vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5rem;
}

/* Hero */

.hero {
  height: 65vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5rem;
}

/* About */

.about-label {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-vertical-line {
  border-left: 1px solid var(--primary-color);
  height: 90px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

/* Bookmark */

.bookmark-wrapper {
  width: 19rem;
  margin-left: 44px;
}

.bookmark-card {
  margin: 0 auto;
  padding: 0 30px 0 30px;
  font-size: 11px;
}

.bookmark-img {
  position: absolute;
  left: -41px;
  top: 14px;
  z-index: 999;
}

/* Judgements */

.judgements-card {
  display: flex;
  align-items: center;
}

/* Acts Details */

.acts-details-box {
  border: 2px solid var(--primary-color);
}

.acts-details-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  background-color: var(--primary-color);
  padding: 0.35rem 2.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 20px;
  border: 2px solid var(--primary-color);
  color: var(--secondary-color);
}

.hr-3 {
  margin-right: 3rem;
  margin-left: 3rem;
  border: 0;
  border-top: 2px solid var(--primary-color);
}

/* Footer */

ul {
  padding-inline-start: 0;
}

ul li {
  text-decoration: none;
  list-style-type: none;
  margin: 3px;
}

ul li a {
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: var(--secondary-color);
}

.social-group .social-items {
  display: inline-block;
}

.social-group .social-items a {
  font-size: 20px;
  margin-right: 15px;
}

.brand-group .brand-items {
  display: inline-block;
}

input.contact-field[type="text"],
input.contact-field[type="text"]:hover,
input.contact-field[type="text"]:focus {
  background-color: var(--primary-color);
}

input.contact-field::placeholder {
  color: var(--secondary-color);
}

/* Page Not Found */

.page-not-found-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Slick Slider */

.slick-slide {
  width: 320px !important;
}

.slick-list {
  padding: 0px !important;
}

.slick-arrow {
  height: 40px;
  width: 40px;
}

.slick-prev {
  left: -50px !important;
}

.slick-prev:before,
.slick-next:before {
  background-color: var(--secondary-color);
  color: var(--primary-color) !important;
  font-size: 40px !important;
  border-radius: 50%;
  opacity: 1 !important;
}

.card-wrapper {
  width: 18rem !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Acts Chapters */

.select-card-items-wrapper {
  background-color: #ffd6d4;
  border-radius: 20px;
  padding: 10px 10px 1px 10px;
  position: absolute;
  z-index: 999;
  width: 92%;
}

.select-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.5rem;
}

/* Media Queries */

@media (max-width: 575.98px) {
  /* Hero Responsive */

  .hero {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 25vh;
    margin-top: 9rem;
  }

  .hero img {
    display: none;
  }

  .lg-text-2 {
    font-size: 2.3rem;
  }

  .lg-text-3 {
    font-size: 2.5rem;
  }

  .lg-text-4 {
    font-size: 2rem;
  }

  .hero-home img {
    display: none;
  }

  /* Bookmark */

  .bookmark-wrapper {
    width: auto;
    margin-left: 36px;
  }

  /* Acts Button */

  .acts-btn-wrapper {
    overflow-x: auto;
    justify-content: normal;
  }

  .acts-btn-wrapper::-webkit-scrollbar {
    width: 0;
  }

  /* Card In Short */

  .font-h4 {
    font-size: 1.1rem !important;
  }

  .font-xsm {
    font-size: 0.9rem !important;
  }

  /* Footer */

  .mrt-mrb-3 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .mrt-3 {
    margin-top: 0;
  }

  .mrt-5 {
    margin-top: 1rem;
  }

  .t-center {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  /* Hero Responsive */

  .hero {
    display: flex;
    justify-content: space-evenly;
    height: 50vh;
    margin-top: 6rem;
  }

  .hero img {
    width: 250px;
  }

  .hero-home img {
    display: none;
  }

  .lg-text-2 {
    font-size: 4rem;
  }

  .lg-text-3 {
    font-size: 60px;
  }

  .lg-text-4 {
    font-size: 45px;
  }

  /* Acts Button */

  .acts-btn-wrapper {
    overflow-x: auto;
    justify-content: normal;
  }

  .acts-btn-wrapper::-webkit-scrollbar {
    width: 0;
  }

  /* Footer */

  .mrt-mrb-3 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .mrt-3 {
    margin-top: 0;
  }

  .mrt-5 {
    margin-top: 1rem;
  }

  .t-center {
    text-align: center;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Hero Responsive */

  .hero {
    display: flex;
    justify-content: space-evenly;
    height: 50vh;
    margin-top: 6rem;
  }

  .hero img {
    width: 270px;
  }

  /* Bookmark */

  .bookmark-wrapper {
    width: 19rem;
    margin-left: 26px;
    display: flex;
    justify-content: center;
  }

  /* Acts Button */

  .acts-btn-wrapper {
    justify-content: center !important;
  }

  /* Footer */

  .mrt-mrb-3 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .mrt-3 {
    margin-top: 0;
  }

  .mrt-5 {
    margin-top: 1rem;
  }

  .t-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  /* Acts Button */

  .acts-btn-wrapper {
    justify-content: center !important;
  }
}

.writer_auther{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.writer{
  background: #102037;
  color: #ffffff;
  width: fit-content;
  padding: 8px 40px;
  border-radius: 17px;
}
.update_corner_filter{
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
}
.update_corner_filter button{
  border: 1px solid;
  border-radius: 8px;
  background-color: transparent;
  color: #102037;
  padding: 8px 40px;
}
.update_corner_filter .active{
  background-color: #102037 !important;
  color: #ffffff;
}
.blog_img {
  width: 100%;
  height: 250px !important;
  box-shadow: 0 0 11px #e9e9e9;
  border-radius: 5px;
  object-fit: cover;
}

.update_control {
  border: 1px solid;
  border-radius: 8px;
  background-color: transparent;
  color: #102037;
  width: 150px;
  cursor: pointer;
}
.update_control input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.update_control .control__content {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.update_control:hover .control__content {
  background-color: transparent;
}

.update_control input:focus ~ .control__content {
  background-color: transparent;
}

.update_control input:checked ~ .control__content {
  background-color: #102037;
  border-radius: 6px;
  border: none;
  color: #ffffff;
}
.form_filters{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.search_box{
  width: 30%;
}
.MuiOutlinedInput-root{
  border-radius: 50px !important;
}
.form_drop .btn-secondary {
  color: #fff;
  background-color: #102037 !important;
  border-color: #102037 !important;
  width: 130px;
  border-radius: 50px;
}
.form_drop .btn-secondary:hover {
  color: #fff;
  background-color:#102037 !important;
  border-color: #102037 !important;
}
.form_drop .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color:#102037 !important;
  border-color: #102037 !important;
}
.form_d {
  padding: 30px;
  padding-left: 60px !important;
  margin-bottom: 50px;
}
.form_d h2{
  font-family: "Public Sans", sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #102037;
}
.form_d p{
  font-size: 18px;
  color: #586373;
}
.form_filters_lang{
  display: flex;
  justify-content: space-around;
  max-width: 80%;
  margin: auto;
  margin-bottom: 30px !important;
}
.form_drop select {
  color: #fff;
  background-color: #102037;
  border-color: #102037;
  width: 130px;
  border-radius: 50px;
  padding: 7px;
  text-transform: capitalize;
}
.state_drop select, .city_drop select{
  color: #102037;
  background-color: transparent;
  border: 1px solid;
  border-color: #102037;
  width: 130px;
  border-radius: 10px;
  padding: 7px;
  text-transform: capitalize;
}
.nave_active {
  border-bottom: 2px solid;
}
.card-body {
  display: grid;
}